/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import { useSpring, animated as a, config } from "react-spring";
import Cursor from "./Cursor";
import Layout from "./Layout";

type Props = {
  children: React.ReactNode;
  transitionStatus: "exiting" | "exited" | "entering" | "entered";
  entry?: { delay: number } & any;
  exit?: { length: number } & any;
  mount?: boolean;
  uri: string;
};

const Page = ({ children, transitionStatus, uri }: Props) => {
  // Spring creation
  const { x } = useSpring({
    config: { ...config.gentle, clamp: true },
    x: transitionStatus === "entered" ? 0 : -100,
  });

  return (
    <Fragment>
      {/* Cursor Indicator */}
      <Cursor />
      {/* Page transition */}
      <a.div
        style={{ transform: x.to((v) => `translateX(${v}%)`) }}
        sx={{
          alignItems: "center",
          bg: "black",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          overflow: "hidden",
          position: "absolute",
          left: "100%",
          top: 0,
          transformOrigin: "right",
          width: "100%",
          willChange: "transform",
          zIndex: 100,
        }}
      />
      {/* Layout */}
      <Layout uri={uri}>{children}</Layout>
    </Fragment>
  );
};

export default Page;
