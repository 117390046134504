export const hiID = "hi";
export const aboutID = "about";

export const scrollToHiSection = (): void => {
  document.querySelector(`#${hiID}`)?.scrollIntoView({ behavior: "smooth" });
};

export const scrollToAboutSection = (): void => {
  document.querySelector(`#${aboutID}`)?.scrollIntoView({ behavior: "smooth" });
};
