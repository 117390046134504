import IconCodePen from "../components/IconCodePen";
import IconGitHub from "../components/IconGitHub";
import IconLinkedIn from "../components/IconLinkedIn";
import IconMail from "../components/IconMail";
import IconTwitter from "../components/IconTwitter";

export default [
  {
    id: "github",
    color: "#24292E",
    href: "https://github.com/jgrancher",
    icon: IconGitHub,
    target: "_blank",
    title: "Check out my code",
  },
  {
    id: "codepen",
    color: "#AE63E4",
    href: "https://codepen.io/jgrancher",
    icon: IconCodePen,
    target: "_blank",
    title: "Check out some experiments",
  },
  {
    id: "linkedin",
    color: "#006192",
    href: "https://www.linkedin.com/in/jgrancher",
    icon: IconLinkedIn,
    target: "_blank",
    title: "Let's connect on LinkedIn",
  },
  {
    id: "twitter",
    color: "#1DA1F2",
    href: "https://twitter.com/jgrancher",
    icon: IconTwitter,
    target: "_blank",
    title: "Contact me on Twitter",
  },
  {
    id: "mail",
    color: "#DB4437",
    href: "mailto:jgrancher@gmail.com?subject=Hello!",
    icon: IconMail,
    target: "_blank",
    title: "Send me an email",
  },
];
