/** @jsxImportSource theme-ui */
import { useCallback, useEffect } from "react";
import { useSpring, animated as a, config } from "react-spring";

const Cursor = () => {
  // Spring creation: Mouse indicator
  const [{ opacity, xy }, api] = useSpring(() => ({
    config: { ...config.stiff, duration: 0.01 },
    opacity: 0,
    xy: [0, 0],
  }));

  // On mount
  useEffect(() => {
    if (!matchMedia("(hover: hover)").matches) return;

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      api.stop();
    };
  }, []);

  // Callback
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    const noCursorHovered = document.querySelector("[data-no-cursor]:hover");
    api.start({ opacity: noCursorHovered ? 0 : 1, xy: [x, y] });
  }, []);

  return (
    <a.div
      style={{
        opacity,
        transform: xy.to((x, y) => `translate3d(${x}px, ${y}px, 0) translate3d(-50%, -50%, 0)`),
      }}
      sx={{
        bg: "black",
        borderRadius: (t) => Number(t.sizes[1]) / 2,
        display: "none",
        height: 1,
        left: 0,
        pointerEvents: "none",
        position: "fixed",
        top: 0,
        width: 1,
        willChange: "opacity, transform",
        zIndex: 100,
        "@supports (mix-blend-mode: difference)": {
          bg: "white",
          mixBlendMode: "difference",
        },
        "@media (hover: hover)": {
          display: "block",
        },
      }}
    />
  );
};

export default Cursor;
