/** @jsxImportSource theme-ui */

type Props = {
  fill?: string;
};

const IconTwitter = ({ fill = "black", ...props }: Props) => (
  <svg width={24} height={21} viewBox="0 0 24 21" fill="none" {...props}>
    <path
      d="M8.3 20.9c-2.6 0-5.3-.7-7.7-2.1-.4-.2-.6-.7-.5-1.1.1-.4.5-.7.9-.7 1.7.1 3.4-.3 4.9-1-3.7-2.3-4.7-5.5-4.8-8-.1-2.9.9-5.3 1-5.4.1-.3.4-.6.8-.6s.7.1.9.4C5.5 4.8 8.1 6.3 11 6.5c0-1.6.7-3.1 1.9-4.2 2.1-1.8 5.2-1.8 7.3 0 .8-.3 1.6-.7 2.3-1.2.3-.2.8-.2 1.1 0 .3.2.5.7.4 1.1-.3 1.4-1 2.7-2 3.8v.5c0 5.5-2.4 10.1-6.7 12.5-2.1 1.3-4.5 1.9-7 1.9zM5 18.5c3.3.8 6.6.4 9.4-1.2 3.6-2.1 5.7-6.1 5.7-10.8 0-.2 0-.4-.1-.6-.1-.3 0-.7.3-.9.2-.2.4-.5.6-.7-.2.1-.5.2-.7.2-.4.1-.8 0-1-.3-1.3-1.4-3.5-1.6-4.9-.3-.9.6-1.3 1.6-1.3 2.6v1c0 .5-.4 1-1 1-3.3.1-6.4-1.2-8.6-3.5-.6 2.6-.8 7.5 5 10 .3.2.6.5.6.9s-.1.7-.4.9c-1.2.8-2.4 1.4-3.6 1.7z"
      sx={{ fill }}
    />
  </svg>
);

export default IconTwitter;
