/** @jsxImportSource theme-ui */

type Props = {
  fill?: string;
};

const IconMail = ({ fill = "black", ...props }: Props) => (
  <svg width={22} height={18} viewBox="0 0 22 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2c-.548 0-1 .452-1 1v12c0 .548.452 1 1 1h16c.548 0 1-.452 1-1V3c0-.548-.452-1-1-1H3zM0 3c0-1.652 1.348-3 3-3h16c1.652 0 3 1.348 3 3v12c0 1.652-1.348 3-3 3H3c-1.652 0-3-1.348-3-3V3z"
      sx={{ fill }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.18 2.427a1 1 0 011.393-.246L11 8.779l9.427-6.598a1 1 0 011.146 1.638l-10 7a1 1 0 01-1.146 0l-10-7A1 1 0 01.18 2.427z"
      sx={{ fill }}
    />
  </svg>
);

export default IconMail;
