import projects from "../data/projects";

const staticRoutes = {
  home: "/",
  about: "/about",
  projects: "/projects",
};

const projectRoutes = Object.fromEntries(
  projects.map((project) => [project.id, project.slug]),
) as Record<typeof projects[number]["id"], string>;

export const routes = {
  ...staticRoutes,
  ...projectRoutes,
};
