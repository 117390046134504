import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Project } from "../data/projects";

type Props = {
  description?: string;
  lang?: string;
  meta?: any[];
  project?: Project;
  title?: string;
};

const SEO = ({ description = "", lang = "en", meta = [], project, title }: Props) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            description
            author
          }
        }
      }
    `,
  );

  // Get site properties
  const { url, description: siteDescription, title: siteTitle } = siteMetadata;

  // Title & Description
  const finalTitle = title || siteTitle;
  const finalDescription = description || siteDescription;

  // Image
  const baseImage = `${url}/images/hello.jpg`;
  const image = project ? `${url}/images/${project.id}.jpg` : baseImage;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={finalTitle}
      titleTemplate={title ? `Jeremy Grancher | %s` : undefined}
      meta={[
        {
          name: `description`,
          content: finalDescription,
        },
        {
          property: `og:title`,
          content: finalTitle,
        },
        {
          property: `og:description`,
          content: finalDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: finalTitle,
        },
        {
          name: `twitter:description`,
          content: finalDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
