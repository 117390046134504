/** @jsxImportSource theme-ui */

type Props = {
  fill?: string;
};

const IconCodePen = ({ fill = "black", ...props }: Props) => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.455.162a1 1 0 011.09 0l10 6.5A1 1 0 0122 7.5v7a1 1 0 01-.455.838l-10 6.5a1 1 0 01-1.09 0l-10-6.5A1 1 0 010 14.5v-7a1 1 0 01.455-.838l10-6.5zM2 8.042v5.915l9 5.85 9-5.85V8.043l-9-5.85-9 5.85z"
      sx={{ fill }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 13.5a1 1 0 011 1V21a1 1 0 11-2 0v-6.5a1 1 0 011-1z"
      sx={{ fill }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.18 6.927a1 1 0 011.393-.246L11 13.279l9.427-6.598a1 1 0 011.146 1.638l-10 7a1 1 0 01-1.146 0l-10-7A1 1 0 01.18 6.927z"
      sx={{ fill }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.427 6.68a1 1 0 011.146 0l10 7a1 1 0 01-1.146 1.64L11 8.72l-9.427 6.6a1 1 0 01-1.146-1.64l10-7z"
      sx={{ fill }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0a1 1 0 011 1v6.5a1 1 0 11-2 0V1a1 1 0 011-1z"
      sx={{ fill }}
    />
  </svg>
);

export default IconCodePen;
