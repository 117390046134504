/** @jsxImportSource theme-ui */
import { Flex } from "theme-ui";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Footer = ({ children, ...props }: Props) => {
  return (
    <Flex
      {...props}
      as="footer"
      sx={{
        alignItems: "center",
        bottom: [0, null, 3],
        justifyContent: "space-between",
        overflowY: "hidden",
        position: "fixed",
        pt: [3, null, 5],
        px: [2, null, 3],
      }}
    >
      {children}
    </Flex>
  );
};

export default Footer;
