/** @jsxImportSource theme-ui */

type Props = {
  fill?: string;
};

const IconLinkedIn = ({ fill = "text", ...props }: Props) => (
  <svg width={22} height={21} viewBox="0 0 22 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8a5 5 0 00-5 5v6h2v-6a3 3 0 016 0v6h2v-6a5 5 0 00-5-5zm0-2a7 7 0 00-7 7v7a1 1 0 001 1h4a1 1 0 001-1v-7a1 1 0 012 0v7a1 1 0 001 1h4a1 1 0 001-1v-7a7 7 0 00-7-7zM0 8a1 1 0 011-1h4a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V8zm2 1v10h2V9H2zM3 2a1 1 0 100 2 1 1 0 000-2zM0 3a3 3 0 116 0 3 3 0 01-6 0z"
      sx={{ fill }}
    />
  </svg>
);

export default IconLinkedIn;
