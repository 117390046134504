/** @jsxImportSource theme-ui */
import { ThemeUIStyleObject, useThemeUI } from "theme-ui";
import { useSpring, animated as a, config, to } from "react-spring";
import { lighten } from "polished";

type Props = {
  color?: string;
  isMenuOpen?: boolean;
  onClick: () => any;
};

const ButtonMenu = ({ color = "text", isMenuOpen = false, onClick }: Props) => {
  const { theme } = useThemeUI();

  // Colors management
  const colorValue = theme.colors[color];
  const colorHover = lighten(0.3, typeof colorValue === "object" ? colorValue[0] : colorValue);

  // Spring creation
  const { r } = useSpring({
    config: config.gentle,
    r: isMenuOpen ? 45 : 0,
  });

  // Common menu bar styles
  const menuBarStyles: ThemeUIStyleObject = {
    bg: isMenuOpen ? "white" : "black",
    borderRadius: 1,
    cursor: "none",
    height: "5px",
    left: 1,
    position: "absolute",
    top: "calc(50% - 2px)",
    transition: "background-color 0.2s ease-in",
    width: (theme) => Number(theme.sizes[4]) - Number(theme.space[1]) * 2,
    willChange: "background-color, transform",
  };

  return (
    <button
      data-no-cursor
      onClick={onClick}
      sx={{
        bg: "transparent",
        border: 0,
        cursor: "none",
        height: 4,
        margin: 0,
        outline: "none",
        pointerEvents: "all",
        position: "relative",
        p: 1,
        py: 12,
        width: 4,
        "&:before": {
          bg: color,
          borderRadius: "50%",
          content: "''",
          cursor: "none",
          height: "140%",
          left: "-20%",
          opacity: isMenuOpen ? 1 : 0,
          position: "absolute",
          top: "-20%",
          transform: isMenuOpen ? "translate3d(0, 0, 0)" : "scale(0.6) translate3d(100%, 0, 0)",
          transition:
            "background-color 0.2s ease-in, opacity 0.3s ease-out, transform 0.3s ease-in",
          width: "140%",
          willChange: "opacity, transform",
        },
        /* Hover state */
        "@media (hover: hover)": {
          "&:hover:before": {
            bg: isMenuOpen ? colorHover : color,
            opacity: 1,
            transform: "translate3d(0, 0, 0)",
          },
          "&:hover span": {
            bg: "white",
          },
        },
      }}
    >
      {/* Top bar */}
      <a.span
        sx={menuBarStyles}
        style={{
          transform: to(
            [r.to((x) => x), r.to({ range: [0, 45], output: [-100, 0] })],
            (r, t) => `rotate(${r}deg) translate3d(0, ${t}%, 0)`,
          ),
        }}
      />
      {/* Bottom bar */}
      <a.span
        sx={menuBarStyles}
        style={{
          transform: to(
            [r.to((x) => x), r.to({ range: [0, 45], output: [100, 0] })],
            (r, t) => `rotate(-${r}deg) translate3d(0, ${t}%, 0)`,
          ),
        }}
      />
    </button>
  );
};

export default ButtonMenu;
