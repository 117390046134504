/**
 * NOTE: This file is used on the server-side by `createPages` API of Gatsby.
 * DO NOT IMPORT REACT COMPONENTS HERE!
 */

const projects = [
  {
    id: "semi-permanent",
    slug: "/semi-permanent",
    name: "Semi Permanent",
    client: "Website",
    color: "semiPermanent",
    icon: "IconProjectSemiPermanent",
    image: {
      alt: "Semi Permanent",
      src: "/images/semi-permanent.jpg",
    },
    video: {
      src: "/videos/semi-permanent.mp4",
      autoPlay: true,
      loop: true,
      playsInline: true,
      muted: true,
    },
    excerpt: "Revamp of Semi Permanent's website to create a bespoke experience.",
    summary:
      "Semi Permanent brings together internationally renowned designers, artists and creative icons for live events, presentations, workshops and parties.<br /><br />ustwo helped Semi Permanent create a new website from the ground up, focussing on the four main pillars of the business: design festivals, brands partnerships, white label events, and content creation.",
    responsibilities:
      "Leading the project from a technical point of view, I was responsible for the website architecture and its stack.<br />I then went on to develop the whole website page by page in just 6 weeks, from creating Content Types in the CMS, to finessing the animations on the front-end with Framer Motion.",
    technologies: [
      {
        icon: "IconReact",
        text: "React, TypeScript, Next.js",
      },
      {
        icon: "👩‍🎤",
        text: "CSS-in-JS with Emotion",
      },
      {
        icon: "IconContentful",
        text: "Contentful CMS & API",
      },
      {
        icon: "IconGraphQL",
        text: "GraphQL with Apollo",
      },
    ],
    highlights: [
      {
        title: "Permanent Record",
        copy: "One of the greatest challenges of this website was to work on different content types existing in the CMS, query them and combine them into this giant library of content, thanks to the Contentful GraphQL API and Apollo. Articles, Profiles, Past Events are all filterable by tag and reading time.",
        image: null,
        video: {
          src: "/videos/semi-permanent-highlight-1.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "The Event page",
        copy: 'The Event page combines a set of bespoke front-end components designed to detail events in the most comprehensive yet creative way possible. The Speakers & Artists "cloud" is one of them, grouping artists by theme whilst keeping things visual and playful.',
        image: null,
        video: {
          src: "/videos/semi-permanent-highlight-2.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "Brand Studio",
        copy: "Semi Permanent works with brands to create tailored experiences. Like in the Permanent Record page, a unique grid of case studies has been created which reveals its content dynamically as the user scrolls through the page.",
        image: null,
        video: {
          src: "/videos/semi-permanent-highlight-3.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
    ],
    cta: {
      label: "Visit the website",
      url: "https://semipermanent.com",
    },
    next: "create-with-google",
    inNavigation: true,
  },
  {
    id: "create-with-google",
    slug: "/create-with-google",
    name: "Create with Google",
    client: "Google",
    color: "cwgYellow",
    icon: "IconProjectCWG",
    image: {
      alt: "Google - Create with Google",
      src: "/images/create-with-google.jpg",
    },
    video: {
      src: "/videos/create-with-google.mp4",
      autoPlay: true,
      loop: true,
      playsInline: true,
      muted: true,
    },
    excerpt: "Development and maintenance of Create With Google's website and Inspiration Hub.",
    summary:
      "Ustwo Sydney helped Google in launching a web platform for creative makers.<br /><br />“Create with Google” shows what’s possible across the Google Creative Canvas with tools, creative considerations and inspiration to help bring creative ideas to life.",
    responsibilities:
      "As the main developer of the website, I was responsible for building up the front-end components in accordance with Material Design conventions and Google’s security best practices, and put them together into a multilingual, CMS-driven website visited by thousands daily.",
    technologies: [
      {
        icon: "IconPython",
        text: "Python backend",
      },
      {
        icon: "IconJinja",
        text: "Jinja templates & macros",
      },
      {
        icon: "IconSCSS",
        text: "HTML5 & SCSS",
      },
      {
        icon: "IconMaterialDesign",
        text: "Material Design for Web",
      },
    ],
    highlights: [
      {
        title: "Animating SVGs",
        copy: "To give life to the website, I animated a few scribbles as soon as they become visible in the viewport.<br /><br />Each of these SVGs is composed of the path of the scribble, used as a mask, and an image. By animating the <code>stroke-dashoffset</code> CSS property of the mask, the image underneath is progressively revealed, producing a nice impression of the image being painted.",
        image: null,
        video: {
          src: "/videos/create-with-google-highlight-1.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "Inspiration hub",
        copy: "The inspiration hub is where one can find the best case studies of what's possible across the Google platforms.<br /><br />The page is rendered with all case studies by default. A series of <code>data-*</code> attributes are applied to the cards, referring to their properties: platforms, verticals, etc. It is then possible to filter the cards on the front-end through the sidebar.",
        image: null,
        video: {
          src: "/videos/create-with-google-highlight-2.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "CMS-driven pages and sections",
        copy: "The whole website content is dynamic and multilingual, provided by a headless CMS.<br /><br />Each page has been thought individually to receive a specific list of section types, allowing for just the right level of customisation. These sections are then parsed and rendered from their associated Jinja components (using macros).",
        image: null,
        video: {
          src: "/videos/create-with-google-highlight-3.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
    ],
    cta: {
      label: "Visit the website",
      url: "https://create.withgoogle.com",
    },
    next: "qantas-card-companion",
    inNavigation: true,
  },
  {
    id: "qantas-card-companion",
    slug: "/qantas-card-companion",
    name: "Card Companion",
    client: "Qantas",
    color: "qantasRed",
    icon: "IconProjectQCC",
    image: {
      alt: "Qantas - Credit Card Companion",
      src: "/images/qantas-card-companion.jpg",
    },
    video: {
      src: "/videos/qantas-card-companion.mp4",
      autoPlay: true,
      loop: true,
      playsInline: true,
      muted: true,
    },
    excerpt:
      "Creation of a web platform to help educate Qantas's card holders on the ways in which to earn, track and spend points.",
    summary:
      'There are 12 million Qantas Frequent Flyer Members and 58% of all Qantas Points are earned through QPECCs (Qantas Points Earning Credit Cards). Qantas Loyalty wanted to build a new product for QPECC holders to track their points, view offers and rewards and maximise their point earning potential.<br /><br />We helped them create a go-to portal for everything "Qantas Points" related: Users can link a series of Credit Cards and track their earning history, maximise it with special offers and eventually set goals to be able to spend them on what they love.',
    responsibilities:
      "Acting as the lead front-end developer, I built the majority of the visible parts of the platform: some data visualization tools with Nivo, an animated onboarding experience with CSS transitions, a component library with Storybook, and a consistent and scalable overall UI built using Styled System.",
    technologies: [
      {
        icon: "IconReact",
        text: "React, TypeScript, Next.js",
      },
      {
        icon: "IconStorybook",
        text: "Storybook",
      },
      {
        icon: "💅",
        text: "Styled System",
      },
      {
        icon: "IconGraphQL",
        text: "GraphQL with Apollo",
      },
    ],
    highlights: [
      {
        title: "Component Library",
        copy: "Even though Qantas had an existing internal component library, we decided to create our own, specific to this project whilst leveraging some of their existing components.<br /><br />Using Storybook allowed us to drastically improve our collaboration with designers, and, from tiny modules to full pages, progressively build up the product and test it in various application states.",
        image: null,
        video: {
          src: "/videos/credit-card-companion-highlight-1.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "Data visualisation",
        copy: 'A major feature of this product is to track how many Qantas points a user earned in the last twelve months.<br /><br />Using the amazing data visualisation library called <a href="https://nivo.rocks/" rel="noreferrer noopener" target="_blank"><code>Nivo</code></a>, I prototyped the first iterations of this component and progressively customised it to add business and UI logic to it.',
        image: null,
        video: {
          src: "/videos/credit-card-companion-highlight-2.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
      {
        title: "The onboarding animations",
        copy: "After having created most of the UI rapidly but consistently (thanks to the amazing Styled System library), we wanted to focus on finessing the onboarding experience.<br /><br />This is when we added a series of CSS transitions and animations with packages like <code>next-page-transitions</code> to effortlessly transition between pages.",
        image: null,
        video: {
          src: "/videos/credit-card-companion-highlight-3.mp4",
          autoPlay: true,
          loop: true,
          playsInline: true,
          muted: true,
        },
      },
    ],
    cta: {
      label: "Visit the website",
      url: "https://cardcompanion.qantas.com",
    },
    next: "audience-connect",
    inNavigation: true,
  },
  {
    id: "audience-connect",
    slug: "/audience-connect",
    name: "Audience Connect",
    client: "Google",
    color: "cwgBlue",
    icon: "IconProjectAC",
    image: {
      alt: "Google - Audience Connect",
      src: "/images/audience-connect.jpg",
    },
    video: {
      src: "/videos/audience-connect.mp4",
      poster: "/images/audience-connect-poster.png",
      autoPlay: false,
      controls: true,
      loop: true,
      playsInline: true,
      muted: false,
    },
    excerpt:
      "Creation of a desktop application to complement Google Slides with offline videos and real-time interactions.",
    summary:
      "The Create with Google market leads had a need for a tool to let them seamlessly present to, inspire and engage with an audience of creative makers across APAC, where sometimes the internet bandwidth can be low.<br /><br />With these requirements in mind, ustwo created a sophisticated desktop application to enhance the capabilities of Google Slides with offline high quality videos and real-time interactions and exercices with the audience.",
    responsibilities:
      "Principal engineer on this project, I created a web-based prototype in 6 weeks then iteratively built upon it by laying technical foundations of the real-time interactions, architecturing a desktop application and releasing new features & interactions.<br />18 months later, we have released and are still maintaining a robust Windows & MacOS application being used worldwide for creative and engaging presentations.",
    technologies: [
      {
        icon: "IconReact",
        text: "Preact",
      },
      {
        icon: "IconElectron",
        text: "Electron",
      },
      {
        icon: "IconFirebase",
        text: "Firebase Realtime Database",
      },
      {
        icon: "IconJest",
        text: "Jest & Enzyme",
      },
    ],
    highlights: [
      {
        title: "Google Slides enhancing",
        copy: "The main promise of this product is to be able to interact with your audience.<br /><br />The first part of the process is to “decorate” your slides with metadata, in order to know which slides are interactive. We have used a Google Slides Add-on to do so. A presenter can add an interaction from this panel onto the selected slide, which will be read as metadata by the Electron app.",
        image: {
          alt: "Audience Connect application with the Google Slides Add-on activated",
          title: "Audience Connect application with the Google Slides Add-on activated",
          width: 1280,
          height: 800,
          src: "/images/audience-connect-highlight-1.jpg",
        },
        video: null,
      },
      {
        title: "Firebase Realtime",
        copy: "During a presentation, the application will read the current slide the presenter is on, and detect whether it’s an interactive one or not. If it is, it will create an interaction ID in <code>Firebase</code>, that will automatically be picked up in real-time by the members of the audience (see next point). A slide can also play an offline video, in which case Electron will directly stream it from the presenter’s computer.",
        image: {
          alt: "Real-time communication between the presentation and the audience view",
          title: "Real-time communication between the presentation and the audience view",
          width: 1280,
          height: 720,
          src: "/images/audience-connect-highlight-2.jpg",
        },
        video: null,
      },
      {
        title: "The Audience view",
        copy: "Before each presentation, the presenter asks its audience to “connect” to the room by entering a unique session code. Once in, all the interactions triggered by the presenter will be seen in real-time onto the audience member’s phone (questions, exercices, etc). This whole part of the product has been built in Preact, Material Design & Tailwind CSS.",
        image: {
          alt: "The audience view of the application lets you interact with the presentation through exercises and questions",
          title:
            "The audience view of the application lets you interact with the presentation through exercises and questions",
          width: 1280,
          height: 720,
          src: "/images/audience-connect-highlight-3.jpg",
        },
        video: null,
      },
    ],
    cta: {
      label: "Visit the website",
      url: "https://create.withgoogle.com/tools/audience-connect",
    },
    next: "semi-permanent",
    inNavigation: true,
  },
  {
    id: "succailure-tv",
    slug: "/succailure-tv",
    name: "Succailure TV",
    client: "ustwo",
    color: "ustwoGreen",
    icon: "IconProjectSTV",
    image: null,
    video: {
      src: "/videos/succailure-tv.mp4",
      autoPlay: true,
      loop: true,
      playsInline: true,
      muted: true,
    },
    excerpt:
      "Slack bot & web application to stay connected to the four different ustwo studios across the world.",
    summary:
      '"ustwo studios" is composed of four major studios across the world. At ustwo Sydney, we sometimes felt a little bit isolated from the rest of us, and wanted to find an amusing way to reconnect with them.<br /><br />So, we decided to create a Slack bot that listens to messages reactions in our 4 studios\' Slack channels, and re-broadcast them into a web application that was displayed on the offices TVs, usually by the reception. By walking past it every day, ustwobies could see what happened in the other studios and have a smile.',
    responsibilities:
      "I developed a Slack bot with Node & Express.js to listen to Slack messages that would receive a 📺 emoji reaction, meaning that someone would want to broadcast it.<br /><br />These messages are stored in Firebase and then used in a Next.js application, displayed in a colorful carousel that transitions between each item nicely.",
    technologies: [
      {
        icon: "IconNodeJS",
        text: "NodeJS & Slack API",
      },
      {
        icon: "IconFirebase",
        text: "Firebase Realtime Database",
      },
      {
        icon: "IconReact",
        text: "React & Next.js",
      },
      {
        icon: "💅",
        text: "Styled-Components",
      },
    ],
    highlights: null,
    cta: {
      label: "Check the website (requires Slack login)",
      url: "https://succailure.tv",
    },
    next: "semi-permanent",
    inNavigation: false,
  },
] as const;

// Filtered projects that should appear in the listing / navigation
export const projectsInNavigation = projects.filter((p) => p.inNavigation);

export type Project = typeof projects[number];

export default projects;
