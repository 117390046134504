/** @jsxImportSource theme-ui */
import ButtonLink from "../components/ButtonLink";
import { routes } from "../utils/routes";

export default {
  title: <>Bonjour.</>,
  paragraph1: (
    <>
      I am a Sydney-based senior web developer who loves to craft beautiful digital products using
      web technology. I take special care for little details and interactions, following best
      practices, web standards and code hygiene.
    </>
  ),
  paragraph2: (
    <>
      Lucky enough to have been able to collaborate with Publicis, Josephmark, Google, ustwo, and
      TBWA, I now work as a freelancer (mostly with{" "}
      <ButtonLink hasUnderline={false} href="https://userkind.com.au" target="_blank">
        Userkind
      </ButtonLink>
      ) on websites, apps and digital products.
    </>
  ),
  cta: <ButtonLink to={routes.projects}>Check out my latest work.</ButtonLink>,
};
