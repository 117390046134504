/** @jsxImportSource theme-ui */

type Props = {
  children: React.ReactNode;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  hasHTML?: boolean;
};

const Copy = ({ children, hasHTML = false, ...rest }: Props) => {
  const text = hasHTML ? undefined : children;
  const props: any = {
    ...rest,
    ...(hasHTML ? { dangerouslySetInnerHTML: { __html: children } } : {}),
  };

  return (
    <p
      {...props}
      sx={{
        fontSize: ["xs", "md", 20],
        lineHeight: ["lg"],
        letterSpacing: "sm",
      }}
    >
      {text}
    </p>
  );
};

export default Copy;
