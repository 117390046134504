/** @jsxImportSource theme-ui */
import { useCallback, useEffect } from "react";
import { useSpring, animated as a, config } from "react-spring";
import { keyframes } from "@emotion/react";
import { transitionTimes } from "../utils/transitions";
import { routes } from "../utils/routes";

const slide = keyframes`
  0% { transform: scaleY(0) translateY(0); }
  70% { transform: scaleY(1) translateY(0); }
  100% { transform: scaleY(1) translateY(100%); }
`;

type Props = {
  uri: string;
};

const ScrollDown = ({ uri }: Props) => {
  // Spring creation
  const [{ opacity, transform }, api] = useSpring(() => ({
    config: { ...config.slow, clamp: true },
    opacity: 0,
    transform: "translateY(50%)",
  }));

  // Callback
  const setVisibility = (scrollTop: number) => {
    api.start({
      transform: scrollTop === 0 ? `translateY(0%)` : `translateY(50%)`,
      opacity: scrollTop === 0 ? 1 : 0,
    });
  };

  // On mount / unMount
  useEffect(() => {
    const main: HTMLElement = document.querySelector("#main");
    if (main && main.scrollHeight > main.offsetHeight) {
      main.addEventListener("scroll", onScroll);
      setTimeout(() => setVisibility(main.scrollTop), transitionTimes.xxl);

      return () => {
        main.removeEventListener("scroll", onScroll);
        api.stop();
      };
    }
  }, []);

  // onScroll callback
  const onScroll = useCallback((e) => {
    setVisibility(e.target.scrollTop);
  }, []);

  return (
    <a.aside
      style={{ opacity, transform }}
      sx={{
        display: "inline-flex",
        fontSize: ["sm", null, "md"],
        letterSpacing: "sm",
        opacity: 0,
        overflow: "hidden",
        transform: "translateY(50%)",
        willChange: "opacity, transform",
      }}
    >
      <div
        sx={{
          bg: "white",
          borderTopLeftRadius: 1,
          borderTopRightRadius: 1,
          display: "inline-block",
          height: [3, 4],
          mr: 2,
          overflow: "hidden",
          width: "1px",
        }}
      >
        <div
          sx={{
            animation: `${slide} 1s ease-out forwards infinite`,
            bg: uri === routes.home ? "primary" : "text",
            height: "100%",
            transformOrigin: "top",
          }}
        />
      </div>
      <p
        sx={{
          color: "text",
          fontWeight: "bold",
          lineHeight: "xxl",
        }}
      >
        Scroll down
      </p>
    </a.aside>
  );
};

export default ScrollDown;
