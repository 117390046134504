/** @jsxImportSource theme-ui */
import { useThemeUI } from "theme-ui";
import { useSpring, animated as a, config } from "react-spring";
import Copy from "./Copy";
import TransitionLink from "./TransitionLink";
import { Project } from "../data/projects";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  project?: Project;
  to?: string;
};

const MenuItem = ({ children, onClick, project, to }: Props) => {
  const { theme } = useThemeUI();

  // Colors management
  const baseColor = project ? "textSecondary" : "text";

  // Spring creation
  const [{ x }, api] = useSpring(() => ({
    config: { ...config.gentle, clamp: true },
    x: 0,
  }));

  // Link creation
  const Link = to ? TransitionLink : Copy;

  // Styles for sub items
  const sublistStyles = {
    fontSize: ["md", "lg", "xl"],
    lineHeight: "lg",
  };

  return (
    <Link
      onMouseEnter={() => api.start({ x: 100 })}
      onMouseLeave={() => api.start({ x: 0 })}
      onClick={onClick}
      to={to}
      sx={{
        display: "block",
        fontSize: ["xl", "xl", "xxxl"], // Override Copy resp. styles
        fontWeight: "extraBold",
        height: "100%",
        lineHeight: "md",
        pl: [2, null, 3, null, 5],
        textDecoration: "none",
        width: "100%",
        ...(project && sublistStyles),
      }}
    >
      <a.span
        style={{
          backgroundImage: x.to(
            (x) =>
              `linear-gradient(90deg, ${theme.colors.primary} ${x}%, ${theme.colors[baseColor]} 0%)`,
          ),
        }}
        sx={{
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          backgroundImage: `linear-gradient(90deg, ${theme.colors.primary} 0%, ${theme.colors[baseColor]} 0%)`,
          textFillColor: "transparent",
        }}
      >
        {children}
      </a.span>
    </Link>
  );
};

export default MenuItem;
