/** @jsxImportSource theme-ui */
import { useRef } from "react";
import { useIntersection } from "react-use";
import { useTrail, animated as a, config } from "react-spring";
import { ThemeUIStyleObject } from "theme-ui";
import ButtonLink from "./ButtonLink";
import { socials } from "../data";
import { transitionTimes } from "../utils/transitions";

const styles: ThemeUIStyleObject = {
  alignItems: "center",
  background: "none",
  border: 0,
  cursor: "none",
  display: "flex",
  height: 42,
  justifyContent: "center",
  p: 0,
  width: 42,
  "&:focus": {
    outlineWidth: 2,
  },
};

const SocialLinks = (props: any) => {
  // Intersection Observer
  const ref = useRef(null);
  const threshold = 1;
  const intersection = useIntersection(ref, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // Spring creation
  const trail = useTrail(socials.length, {
    config: { ...config.gentle, clamp: true },
    delay: inView ? transitionTimes.md : 0,
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0%)" : "translateY(100%)",
  });

  return (
    <ul {...props} ref={ref} sx={{ alignItems: "stretch", display: "inline-flex" }}>
      {socials.map((link, i) => {
        // Styles
        const { opacity, transform } = trail[i];

        // Link creation
        const IconComponent = link.icon;
        const { color, id, href, target, title } = link;
        const linkProps = {
          ...(href && { href }),
          ...(target && { target }),
        };

        return (
          <a.li
            key={id}
            style={{ opacity, transform }}
            sx={{
              willChange: "opacity, transform",
              "&:not(:last-child)": {
                mr: 2,
              },
            }}
          >
            <ButtonLink {...linkProps} color={color} hasUnderline={false} sx={styles} title={title}>
              <IconComponent />
            </ButtonLink>
          </a.li>
        );
      })}
    </ul>
  );
};

export default SocialLinks;
