/** @jsxImportSource theme-ui */
import { Fragment, memo, useMemo } from "react";
import { useThemeUI } from "theme-ui";
import { useSpring, useTrail, animated as a, config } from "react-spring";
import { rgba } from "polished";
import MenuItem from "./MenuItem";
import SocialLinks from "./SocialLinks";
import { projectsInNavigation } from "../data";
import { routes } from "../utils/routes";
import { scrollToAboutSection, scrollToHiSection } from "../utils/helpers";

type Props = {
  isMenuOpen?: boolean;
  onToggleMenu: () => void;
  uri: string;
};

const Menu = ({ isMenuOpen = false, onToggleMenu, uri }: Props) => {
  const { theme } = useThemeUI();

  // Colors management (appease TS)
  const color = theme.colors.text;
  const bgColor = rgba(typeof color === "object" ? color[0] : color, 0.8);

  // Links management
  const p = typeof document !== "undefined" ? document.location.pathname : "";
  const isHome = p === routes.home;
  const isAbout = (uri === routes.home && p === routes.about) || uri === routes.about;

  // Click callbacks (Some links are "internals" and won't open another page!)
  const onClickHome = () => {
    onToggleMenu();
    scrollToHiSection();
  };

  const onClickAbout = () => {
    onToggleMenu();
    scrollToAboutSection();
  };

  // Menu items creation
  const items = useMemo(
    () => [
      {
        label: "Home",
        onClick: uri === routes.home ? onClickHome : undefined,
        to: uri === routes.home ? undefined : routes.home,
      },
      {
        label: "About",
        onClick: isHome || isAbout ? onClickAbout : undefined,
        to: isHome || isAbout ? undefined : routes.about,
      },
      {
        label: "Projects",
        onClick: uri === routes.projects ? onToggleMenu : undefined,
        to: routes.projects,
      },
      ...projectsInNavigation.map((project) => ({
        label: project.name,
        onClick: uri === project.slug ? onToggleMenu : undefined,
        project,
        to: project.slug,
      })),
    ],
    [uri, onClickHome, onClickAbout, onToggleMenu],
  );

  // Spring creation: Nav container
  const { opacity, x } = useSpring({
    config: { ...config.slow, clamp: true },
    opacity: isMenuOpen ? 1 : 0,
    x: isMenuOpen ? "translate3d(0%, 0, 0)" : "translate3d(100%, 0, 0)",
  });

  // Spring creation: Trail of menu items
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: isMenuOpen ? 1 : 0,
    x: isMenuOpen ? "translate3d(0%, 0, 0)" : "translate3d(16%, 0, 0)",
  });

  return (
    <Fragment>
      <a.div
        onClick={onToggleMenu}
        style={{ opacity }}
        sx={{
          bg: bgColor,
          height: "100%",
          left: 0,
          pointerEvents: isMenuOpen ? undefined : "none",
          position: "absolute",
          top: 0,
          width: "100%",
          willChange: "opacity",
          zIndex: 50,
        }}
      />
      <a.nav
        style={{ transform: x }}
        sx={{
          alignItems: "center",
          bg: "white",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: [2, null, 3],
          pl: [null, null, 0],
          position: "fixed",
          right: 0,
          top: 0,
          width: ["100%", null, "50%"],
          willChange: "transform",
          zIndex: 50,
        }}
      >
        <a.ul
          style={{ opacity }}
          sx={{
            my: "auto",
            overflowX: "hidden",
            overflowY: "auto",
            px: [2, null, 3],
            width: "100%",
            willChange: "opacity",
          }}
        >
          {trail.map((trailItem, index) => {
            const { label, ...rest } = items[index];

            return (
              <a.li
                key={label}
                style={{ opacity: trailItem.opacity, transform: trailItem.x }}
                sx={{
                  mb: index == 2 ? 2 : 0,
                  willChange: "opacity, transform",
                }}
              >
                <MenuItem {...rest}>{label}</MenuItem>
              </a.li>
            );
          })}
        </a.ul>
        <aside
          sx={{
            bottom: [2, null, 3, null, 5],
            display: "flex",
            position: "absolute",
            right: [2, null, 5],
          }}
        >
          <SocialLinks />
        </aside>
      </a.nav>
    </Fragment>
  );
};

export default memo(Menu);
