/**
 * Theme-UI doesn't include transition tokens in its theme specification:
 * https://theme-ui.com/theme-spec
 */

export const transitionTimes: { [k: string]: number } = {
  xxxs: 100,
  xxs: 200,
  xs: 300,
  sm: 500,
  md: 800,
  lg: 1300,
  xl: 2100,
  xxl: 3400,
  xxxl: 5500,
};

// Aliases
transitionTimes.pages = transitionTimes.sm;
