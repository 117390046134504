/** @jsxImportSource theme-ui */
// https://www.gatsbyjs.org/docs/recipes/styling-css/#using-google-fonts
import "typeface-source-code-pro";
import { Flex } from "theme-ui";
import Footer from "./Footer";
import Header from "./Header";
import ScrollDown from "./ScrollDown";

type Props = {
  children: React.ReactNode;
  uri: string;
};

const Layout = ({ children, uri }: Props) => {
  return (
    <Flex
      sx={{
        bg: "background",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        zIndex: 1,
      }}
    >
      <Header uri={uri} />
      <main
        id="main"
        sx={{
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          position: "relative",
          scrollSnapType: "y mandatory",
          zIndex: 1,
        }}
      >
        {children}
      </main>
      <Footer sx={{ pointerEvents: "none", zIndex: 10 }}>
        <ScrollDown uri={uri} />
      </Footer>
    </Flex>
  );
};

export default Layout;
