/** @jsxImportSource theme-ui */
import { memo, useCallback } from "react";
import { ThemeUIStyleObject } from "theme-ui";
import Copy from "./Copy";
import IconLogo from "./IconLogo";
import TransitionLink from "./TransitionLink";
import { scrollToHiSection } from "../utils/helpers";
import { routes } from "../utils/routes";

const styles: ThemeUIStyleObject = {
  cursor: "none",
  display: "flex",
  height: 4,
  lineHeight: (theme) => `${theme.sizes[4]}px`, // TODO: Use `basekick`!
  ml: -1,
  pointerEvents: "all",
  px: 1,
  textDecoration: "none",
  transition: "opacity 0.2s ease-in",
  width: [4, null, `50px`],
  willChange: "opacity",
  "@media (hover: hover)": {
    "&:hover": {
      opacity: 0.5,
    },
  },
};

type Props = {
  closeMenu: () => void;
  uri: string;
};

const Logo = ({ closeMenu, uri }: Props) => {
  // Click callbacks (Some links are "internals" and won't open another page!)
  const onClickHome = useCallback(() => {
    closeMenu();
    scrollToHiSection();
  }, [closeMenu, scrollToHiSection]);

  // Home page: the logo is a button
  if (uri === routes.home) {
    return (
      <Copy onClick={onClickHome} sx={styles}>
        <IconLogo />
      </Copy>
    );
  }

  // Link
  return (
    <TransitionLink to={routes.home} sx={styles}>
      <IconLogo />
    </TransitionLink>
  );
};

export default memo(Logo);
