/** @jsxImportSource theme-ui */

type Props = {
  fill?: string;
};

const IconLogo = ({ fill = "black", ...props }: Props) => (
  <svg
    {...props}
    viewBox="0 0 424 320"
    sx={{
      fill,
      width: "100%",
    }}
  >
    <path d="M293.76 203.04h49.68v23.76c-4.32 3.024-9.072 6.048-14.256 8.64-9.072 4.752-22.464 8.64-39.744 8.64-47.52 0-84.24-36.72-84.24-84.24 0-47.088 36.72-84.24 84.24-84.24 24.192 0 43.632 9.504 57.888 19.44 8.208 5.616 15.552 12.096 22.032 19.44l49.68-56.16c-9.072-10.8-19.872-20.304-32.4-28.944C364.608 14.256 332.64 0 289.44 0c-96.768 0-168.48 71.28-168.48 159.84s71.712 159.84 168.48 159.84c44.496 0 77.76-15.12 100.224-30.24 13.392-9.072 24.624-19.008 33.696-30.24V142.56h-129.6v60.48z" />
    <path d="M0 280.8c6.048 7.344 13.392 13.824 22.032 19.44 15.12 9.936 37.152 19.44 68.688 19.44 66.96 0 112.32-45.36 112.32-112.32v-48.32h-82.08v48.32c0 22.032-14.688 36.72-36.72 36.72-10.8 0-19.008-3.888-24.192-8.64-3.024-2.592-6.048-5.616-8.208-8.64L0 280.8z" />
  </svg>
);

export default IconLogo;
