/** @jsxImportSource theme-ui */
import { LinkProps } from "theme-ui";
import Link from "gatsby-plugin-transition-link";
import { transitionTimes } from "../utils/transitions";

// Link uses times in seconds
const transitionTime = transitionTimes.pages / 1000;

type Props = {
  className?: string;
  to: string;
} & LinkProps;

const TransitionLink = (initialProps: Props) => {
  const props = {
    ...initialProps,
    exit: { length: transitionTime },
    entry: { length: transitionTime, delay: transitionTime },
  };

  return <Link {...props} />;
};

export default TransitionLink;
