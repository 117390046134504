/** @jsxImportSource theme-ui */
import { useThemeUI } from "theme-ui";
import { invert } from "polished";
import TransitionLink from "./TransitionLink";
import { transitionTimes } from "../utils/transitions";

type Props = {
  children: React.ReactNode;
  className?: string;
  color?: string;
  hasUnderline?: boolean;
  href?: string;
  onClick?: () => void;
  rel?: string;
  target?: string;
  title?: string;
  to?: string;
};

const ButtonLink = ({
  children,
  color = "primary",
  hasUnderline = true,
  href,
  target = "_self",
  to,
  ...rest
}: Props) => {
  // Invert the given color to use mix-blend-mode
  const { theme } = useThemeUI();
  const isColorInTheme = Object.keys(theme.colors).indexOf(color) !== -1;
  const hex = isColorInTheme ? theme.colors[color] : color;
  const invertHex = invert(typeof hex === "object" ? hex[0] : hex);

  const transitionDelay = hasUnderline ? transitionTimes.xxxs : 0;

  let Component: any = "button";
  if (href) Component = "a";
  if (to) Component = TransitionLink;

  const props = {
    target,
    ...rest,
    ...(href && { href }),
    ...(to && { to }),
  };

  return (
    <Component
      {...props}
      {...(href && target === "_blank" && { rel: "noopener noreferrer" })}
      data-no-cursor
      sx={{
        backfaceVisibility: "hidden",
        background: "none",
        border: 0,
        color: "black",
        display: "inline-flex",
        fontFamily: "body",
        margin: 0,
        outlineColor: color,
        overflow: "hidden",
        position: "relative",
        px: "4px",
        py: 0,
        textDecoration: "none",
        transition: `color ${transitionTimes.xxs}ms ease-in-out ${transitionDelay}ms, transform ${transitionTimes.xxxs}ms ease-out`,
        willChange: "color, transform",
        zIndex: 1,
        /* Underlying path */
        "& path": {
          fill: "black",
        },
        /* Hover state */
        "@media (hover: hover)": {
          "&:hover": {
            transform: "translateY(1px)",
          },
          "&:hover:before, &:hover:after": {
            transform: "translateX(0)",
            transition: `transform ${transitionTimes.xxs}ms ease-in-out ${transitionDelay}ms`,
          },
          "&:hover span": {
            transform: "scaleX(0) translateY(-1px)",
          },
        },
        /* Focus & active states */
        "&:focus, &:active": {
          transform: "translateY(1px)",
        },
        /* Pseudo elements */
        "&:before, &:after": {
          content: "''",
          display: "block",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          transform: "translateX(-110%)",
          transformOrigin: "left",
          transition: `transform ${transitionTimes.xxxs}ms ease-in`,
          width: "100%",
          willChange: "transform",
          zIndex: -1,
        },
        "&:before": {
          pointerEvents: "none",
          zIndex: 1,
          "@supports (mix-blend-mode: difference)": {
            backgroundColor: "white",
            mixBlendMode: "difference",
          },
        },
        "&:after": {
          bg: color,
          "@supports (mix-blend-mode: difference)": {
            bg: invertHex,
          },
        },
        /* Pseudo-elements: Focus & active states */
        "&:focus:before, &:focus:after, &:active:before, &:active:after": {
          transform: "translateX(0)",
          transition: `transform ${transitionTimes.xxs}ms ease-in-out ${transitionDelay}ms`,
        },
        "&:focus span, &:active span": {
          transform: "scaleX(0) translateY(-1px)",
        },
      }}
    >
      {children}
      {hasUnderline && (
        <span
          sx={{
            bg: color,
            bottom: 0,
            display: "block",
            height: ["2px", null, "4px"],
            left: 0,
            position: "absolute",
            transformOrigin: "left",
            transition: `transform ${transitionTimes.xxs}ms ease-out`,
            width: "100%",
            willChange: "transform",
            zIndex: 1,
          }}
        />
      )}
    </Component>
  );
};

export default ButtonLink;
