/** @jsxImportSource theme-ui */
import { Fragment, useState, useCallback } from "react";
import { Flex } from "theme-ui";
import ButtonMenu from "./ButtonMenu";
import Logo from "./Logo";
import Menu from "./Menu";
import { routes } from "../utils/routes";

type Props = {
  uri: string;
};

const Header = ({ uri }: Props) => {
  // Menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu callback
  const onToggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  // ButtonMenu color
  const color = uri === routes.home || uri === routes.about ? "primary" : "text";

  return (
    <Fragment>
      <Flex
        as="header"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          left: [2, null, 3],
          p: [null, null, 3],
          pointerEvents: "none",
          position: "fixed",
          right: [2, null, 3],
          top: [2, null, 3],
          zIndex: 100,
        }}
      >
        <Logo closeMenu={closeMenu} uri={uri} />
        <ButtonMenu color={color} isMenuOpen={isMenuOpen} onClick={onToggleMenu} />
      </Flex>
      <Menu isMenuOpen={isMenuOpen} onToggleMenu={onToggleMenu} uri={uri} />
    </Fragment>
  );
};

export default Header;
